@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --primary-color: #304a46 !important; 
  --secondary-color: #e3edeb !important;
  --background-color: #F7F7FF !important;
  --accent-color: #263b37 !important;

  --primary-text: #000000 !important;

}

body, html {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.wrapper {
  display: flex;
  height: 100vh;
  padding: 0px !important;
  margin: 0px !important;
}

.aside {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content:start;
  flex: 1 0 10%;
  width: 200px;
  padding: 1em;
  color: white;
  height: 100%;
  transition: all 0.3s ease-out; 
}

.main {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-left: 25%;
  background-color: var(--background-color);
}

.fixed {
  position: fixed;
}

#product-section, #cost-section {
  min-width: 25%;
  line-height: 1.5rem;
  text-align: left;
}

.p-m-0 {
  padding: 0px !important;
  margin: 0px !important;
}

.product-description {
  transition: 0.5s;
  min-height: 200px;
  background-color: var(--background-color);
  padding: 1em;
}

.bg-white {
  background-color: white !important;
}

.bw-dark {
  background-color: #87a2d7;
}

.bw-light {
  background-color: var(--background-color);
}

.bw-primary {
  background-color: var(--primary-color) !important;
}

.bw-primary-outline {
  background-color: transparent !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-text) !important;
}

.bw-primary-outline:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.bw-secondary {
  background-color: var(--secondary-color) !important;
}

.bw-background {
  background-color: var(--background-color);
}

.bw-accent {
  background-color: var(--accent-color) !important;
}

.accent {
  color: var(--accent-color) !important;
}

.bw-danger {
  background: #DB5461 !important;
}

.bw-subtle {
  padding-top: 15%;
  color:#a0a0a0;
  font-size: x-large;
}

div.container-fluid div#basic-navbar-nav.navbar-collapse.collapse div.me-auto.navbar-nav div.bw-nav-color.nav-item.dropdown a#basic-nav-dropdown.dropdown-toggle.nav-link {
  color:white !important;
}

.bw-nav-color {
  color:white !important;
}

.borderless {
  /* border-color: var(--accent-color) !important; */
  border: none;
  outline: none;
}

.hidden {
  display:none;
}

.product-header {
  padding: 1em;
}

.product-content  {
  padding: 1em;
}

.display-linebreak {
  white-space: pre-line;
}


table {
  /* margin: 1em 0 0; */
  padding: 0;
  margin: 0;
}

#product-section > input {
  border: none;
  border-bottom: 2px solid gray;
  line-height: 1.5em;
  width: 100%;
  font-size: 1.5em;
  color: hsla(0, 0%, 0%, 0.685)
}

#cost-section input {
  border: none;
  border-bottom: 2px solid gray;
  line-height: 1.5em;
  width: 100%;
  font-size: 0.75em;
  color: hsla(0, 0%, 0%, 0.685);
  text-align: center;
  font-size: 1rem;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 0 1.5rem;
  border: 0px !important;
  border-color: #335145 !important;
}

.card:hover {
  transition: 0.5s;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

input:focus {
  outline: none;
}

.button {
  background-color: darkred;
  border: none;
  padding: 1em;
  color: white;
  cursor: pointer;
}

.loading-text {
  font-size: small;
  color:gray;
}

.fs-small, .text-small {
  font-size: small !important;
}

.center { 
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-light {
  color: white !important;
}

td.active {
  background: #a6c36f !important;
  color: white;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.menu > div {
  padding: 1em;
  font-size: small;
  border:hsla(0, 0%, 0%, 0.685) 1px solid;
  margin: 0 0 1em;
}

.menu > div:hover {
  background-color:var(--primary-color);
  color: white;
  cursor: pointer;
}

.customPricingOptions {
  border: 2px whitesmoke solid;
  /* border-radius: 1em; */
  padding: 1em;
  margin-bottom: 1em;
}

.rounded {
  border-radius: 2em !important;
}

.close-button {
  border: none !important;
}

.borderless-input {
  box-shadow: 0 0 0 !important; 
  border: 0px transparent !important;
}

tr th:nth-child(2),
tr th:nth-child(3),
tr td:nth-child(2),
tr td:nth-child(3) {
  text-align: center;
}

/* Used to set the defaul accordion button to white. */
.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.dynamic-font-size {
  font-size: clamp(0.5em, 4vw, 1.25em);
}

.tooltip-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.highlightRow {
  background-color: #a0a0a0 !important;
  color: white;
}

.react-datepicker__day.red, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  color: red !important;
}

.text-left {
  text-align: left !important;
}

.stsTitle .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  color: white;
}

.stsTitle .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
  color: white;
}

.not-allowed {
  cursor: not-allowed;
}

.error-border {
  border: 1px solid #dc3545 !important;
}

.text-button {
  background: transparent !important;
  color: black !important;
  border: none !important;
}

.bw-icon {
  color: black;
  font-size: 18px;
  text-align: center;
}

.icon-indicator-rotate {
  transition: all 0.3s linear;
}

.icon-indicator-rotate:hover {
  transform: rotate(25deg);
}

.custom-checkbox .form-check-input:checked {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.no-top-radius {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.no-resize {
  resize: none;
}

.scrollable {
  overflow-y: scroll !important;
  flex: 1 1 auto;
}

.previous-button {
  position: fixed;
  left: 0;
  bottom: 1em;
  width: 180px;
  margin-left: 10px;
  font-size: small;
}

.reset-button {
  position: fixed;
  left: 180px;
  bottom: 1em;
  width: 180px;
  margin-left: 10px;
  font-size: small;
}

.next-button, .save-button {
  position: fixed;
  right: 1em;
  bottom: 1em;
  padding: 1em 2em !important;
  border: hidden !important;
}

.menuLink {
  font-size: small !important;
  margin-right: 2em;
  font-size: 0.75em;
}

.menuLink:hover {
  text-decoration: underline solid orange 1px;
}


.menuLink.active {
  text-decoration: underline solid orange 1px;
}
